:root {
  --main-color: #1C1C1C;
  --color-primary: #FFF3DB;
  --color-secondary: #E3212C;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('../../assets/fonts/Roc_Grotesk_Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

html {
    box-sizing: border-box;
    font-size: 18px;
}
p{
    text-align: center;
    font-weight: 500;
    margin: 50px 0;
    font-size: 1.1666rem;
}
img {
    max-width: 100%;
    height: auto;
    line-height: 1;
}
a {
    text-decoration: none;
    color: var(--main-color);
}
a:hover {
    text-decoration: none;
}

body {
    color: var(--main-color-color);
    background-color: var(--color-primary);
    font: 1rem/1.3 'Roc Grotesk', sans-serif;
    margin: 0;
    padding: 0;
    background-color: black;
}
main {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 0 50px;
    background-color: var(--color-primary);
}
.wrapper {
    padding: 0 20px;
}
.credits .wrapper {
    padding: 0;
}
.center {
    text-align: center;
}
header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 50px;
    position: sticky;
    top: 0;
    background: rgb(255,243,219);
    background: linear-gradient(0deg, rgba(255,243,219,0) 0%, rgba(255,243,219,1) 30%);
}

.logo h1 {
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: 400;
}

.intro-text {
    margin: 200px 0;
}
h2.title {
    color: black;
    text-transform: uppercase;
    text-align: center;
    font-size: 5.55rem;
    font-weight: 500;
    line-height: 0.72; 
    margin: 25px 0;
}
h2.title span, h3 span {
    color: var(--color-secondary);
}
h3 {
    color: black;
    text-transform: uppercase;
    text-align: center;
    font-size: 3.4rem;
    font-weight: 500;
    line-height: 0.8; 
    margin: 25px 0;
}
h4, h5 {
    color: black;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}
h5 {
    text-transform: none;
    font-size: 1.3rem;
}

.bg-1 {
    padding: 50px 0 80px;
    background: url('../../assets/img/bg-1.svg') no-repeat center center;
    background-size: cover;
}
.img-fletxes {
    margin-bottom: 80px;
}
.disc-cover {
    margin-bottom: 90px;

}
.cover-image {
    margin-bottom: 40px;
}
.recomended-bands {
    margin-bottom: 50px;
}
.band-list{
    list-style: none;
    text-align: center;
    margin: 0 0 50px;
    padding: 0;
}
.band-list li {
    margin-bottom: 10px;
}

.your-jam {
    margin-bottom: 50px;

}
.your-jam .wrapper{
    padding: 0 30px;
}
.jam {
    border: 1px solid var(--color-secondary);
    padding: 40px 40px 10px;
}
.jam img {
    width: 100%;
}

.actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

}
.btn {
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 20px;
}
.btn.download {
    background-color: var(--color-secondary);
    color: var(--color-primary);
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btn.share {
    background-color: transparent;
    color: var(--color-secondary);
}

ul.peoble {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
}
ul.peoble li {
    margin-bottom: 10px;
}

.bg-persona {
    background: url('../../assets/img/persona.svg') no-repeat center right;

}
.bg-elipses {
    background: url('../../assets/img/elipses.svg') no-repeat center left;
}

/* audio player */ 

.audio-player {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.play {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.progress-bar {
    flex: 1;
    height: 3px;
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid red;
    position: relative;
    margin: 0 10px;
}

.progress {
    height: 100%;
    background-color: red;
    width: 0;
}

.time {
    font-size: 14px;
}
.play-button {
    background-image: url("../../assets/img/play-icon.svg");
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
}
.pause-button {
    background-image: url("../../assets/img/pause-icon.svg");
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
}

/* menu */

.menu {
    cursor: pointer;
    position: relative;
    width: 25px;
    height: 25px;
    z-index: 100;
}
.menu span:nth-child(1) {
    position: absolute;
    top: 5;
    right: 0;
}

.menu span:nth-child(2) {
    position: absolute;
    top: 15;
    right: 0;
}

.menu span {
    display: block;
    width: 26px;
    height: 2px;
    background-color: var(--main-color);
    margin-bottom: 6px;
    transition: all 0.3s ease-in-out;
}

nav {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
}
nav div {
    background-color: var(--color-secondary);
    width: 600px;
    height: 100%;
    padding-top: 100px;
}
nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    height: 100%;
}
nav a {
    font-size: 1.1666rem;
    line-height: 3;
    color: var(--color-primary);
}


nav.open {
    display: flex;
}

.menu.open span{
    background-color: var(--color-primary);
}

.menu.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 50%;
}

.menu.open span:nth-child(2) {
    transform: rotate(-45deg);
    top: 50%;
}
