.songs {
    text-align: center;
  }

  .songs h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .songs ul {
    list-style: none;
    padding: 0;
  }

  .songs li {
    background: #f0f0f0;
    margin: 8px 0;
    padding: 8px 16px;
    border-radius: 4px;
  }
