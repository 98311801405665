.recomended-bands {
  margin-bottom: 50px;
}

.band-list {
  list-style: none;
  text-align: center;
  margin: 0 0 50px;
  padding: 0;
}

.band-list li {
  margin-bottom: 10px;
}


.outfit-title {
  text-align: center;
  font-family: 'Roc Grotesk';
  margin-top: 20px;
  padding: 40px 0 100px;
  background: url('../../../assets/img/bg-1.svg') no-repeat center center;
  background-size: cover;
}

.music-recommendations, .band-recommendations, .playlist-recommendations {
  margin: 20px 0;
  font-family: 'Roc Grotesk';
}
.outfit-description {
  margin: 20px 0 40px;
  text-align: justify;
  font-family: 'Roc Grotesk';
}
h4 {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 5px 0;
}
