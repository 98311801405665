.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .landing-form {

    padding: 20px;
    border-radius: 10px;

    width: 300px;
  }

  .landing-form div {
    margin-bottom: 15px;
  }

  .landing-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .landing-form input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  .landing-form button {
    width: 100%;
    padding: 10px;
    background: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    opacity: 1;
    transition: opacity 0.3s;
  }
  .landing-form .submit-button {
    width: 100%;
    padding: 10px;
    background: #007BFF;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    opacity: 2;
    transition: opacity 0.3s;
  }

  .landing-form .submit-button:disabled {
    background: #cccccc !important; /* Light gray */
    color: #666666; /* Dark gray */
    color: white;
    cursor: not-allowed;
  }


  .landing-form button:hover {
   /* background: #0056b3;*/
  }

  .error {
    color: red;
    font-size: 0.8em;
  }

  .dropzone{
    border: 3px dashed red;  /* Blue border */
    border-radius: 5px;
  }