.your-jam {
    margin-bottom: 50px;
  }

  .your-jam .wrapper {
    padding: 0 70px;
  }

  .jam {
    border: 1px solid var(--color-secondary);
    padding: 40px 40px 10px;
  }

  .jam img {
    width: 100%;
  }


  .album-cover-jam {
    width: 100%;
  }

  .btn {
    border: none;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 20px;
  }

  .btn.download {
    background-color: var(--color-secondary);
    color: var(--color-primary);
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn.share {
    background-color: transparent;
    color: var(--color-secondary);
  }

  /* audio player */

  .audio-player {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .play {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .progress-bar {
    flex: 1;
    height: 3px;
    border-radius: 2px;
    background-color: transparent;
    border: 1px solid red;
    position: relative;
    margin: 0 10px;
  }

  .progress {
    height: 100%;
    background-color: red;
    width: 0;
  }

  .time {
    font-size: 14px;
  }

  .play-button {
    background-image: url("../../../assets/img/play-icon.svg");
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
  }

  .pause-button {
    background-image: url("../../../assets/img/pause-icon.svg");
    display: block;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
  }
/* Jam.css */

/* ... existing styles ... */

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.share-menu {
  position: relative;
}

.share-options {
  position: absolute;
  left: -50px;
  top: 100%;  /* Position to the right of the share button */
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  display: flex;
  flex-direction: row;  /* Display options in a row */
  z-index: 1000; /* Ensure the menu is above other elements */
}

.share-options button {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.share-options button img {
  width: 24px;  /* Smaller icon size */
  height: 24px;
}

.share-options button:hover {
  background: #f0f0f0;
}