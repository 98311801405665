/* Three Dots Spinner Styles */
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px; /* Adjust as needed */
}

.spinner .dot {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #0e0f0f; /* Blue color */
  animation: bounce 1.5s infinite;
}

.spinner .dot:nth-child(1) {
  animation-delay: 0s;
}

.spinner .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.spinner .dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.hash-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
