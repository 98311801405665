.footer {
    margin: 20px 0;
  }

  .footer h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .audio-player {
    width: 300px;
    height: 50px;
    /*background-color: #ccc;*/
    margin: 10px auto;
  }

  .download-button, .share-button {
    margin: 10px;
    padding: 10px 20px;
    background-color: #E3212C;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  .download-button:hover, .share-button:hover {
    background-color: #C91D27;
  }
